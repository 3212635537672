import { useCallback, useState } from 'react';

import { AppointmentEncounterSummary, AppointmentLetter, UseAppointmentsTypes } from '@/@types';
import { useApplicationContext, useLoaderContext } from '@/contexts';
import { AppointmentsService } from '@/services';

export function useAppointments() {
  const { isLoading, startLoader, stopLoader } = useLoaderContext();
  const { setUpcomingAppointmentsExtended } = useApplicationContext();
  const [encounterSummary, setEncounterSummary] = useState<
    AppointmentEncounterSummary | undefined
  >();

  const [letter, setLetter] = useState<AppointmentLetter | undefined>();
  const findEncounterSummaryByAppointmentId = useCallback(
    async (appointmentId: number) => {
      try {
        startLoader({ isDefaultComponentVisible: false });
        const { data } =
          await AppointmentsService.findEncounterSummaryByAppointmentId(appointmentId);
        setEncounterSummary(data);
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const findLetterByPatientIdAndEncounterIdSchema = useCallback(
    async (appointmentId: number, patientId: number, encounterId: number) => {
      try {
        startLoader();
        const { data } = await AppointmentsService.getLetterByPatientIdAndEncounterId(
          appointmentId,
          patientId,
          encounterId
        );
        setLetter(data);
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const confirmAppointments = useCallback(
    async (params: UseAppointmentsTypes.ConfirmAppointments.Params) => {
      const result = await AppointmentsService.confirmAppointmentsByPatientId({
        confirmed: params.confirmed,
        patientId: params.patientId,
        message: params.message,
      });
      if (!result.data?.success) {
        return false;
      }
      setUpcomingAppointmentsExtended((prev) => {
        return prev.map((appointment) => {
          if (params.keyGroup === appointment.key) {
            return {
              ...appointment,
              confirmed: params.confirmed,
              canceled: params.canceled,
            };
          }
          return appointment;
        });
      });
      return true;
    },
    [setUpcomingAppointmentsExtended]
  );

  const clearLetter = useCallback(() => {
    setLetter(undefined);
  }, []);

  return {
    isLoading,
    encounterSummary,
    letter,
    findEncounterSummaryByAppointmentId,
    findLetterByPatientIdAndEncounterIdSchema,
    clearLetter,
    confirmAppointments,
  };
}
