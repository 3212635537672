import { Variants, motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { BasicCard } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function UpcomingAppointmentsLoading() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <motion.div
      initial='hidden'
      animate='show'
      exit='hidden'
      variants={container}
      className='grid grid-cols-1 justify-start gap-6'
    >
      <motion.div variants={itemVariant} className='space-y-8'>
        <BasicCard className='shadow-none rounded-xl px-1'>
          <ContentLoader
            speed={2}
            width='100%'
            height={600}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0' y='0' rx='10' ry='10' width='100%' height='450' />
                <rect x='0' y='470' rx='30' ry='30' width='100%' height='50' />
                <rect x='0' y='530' rx='30' ry='30' width='100%' height='50' />
              </>
            ) : (
              <>
                <rect x='0' y='0' rx='10' ry='10' width='80%' height='450' />
                <rect x='85%' y='0' rx='4' ry='4' width='50' height='50' />
                <rect x='85%' y='60' rx='4' ry='4' width='50' height='50' />
                <rect x='85%' y='120' rx='4' ry='4' width='50' height='50' />
                <rect x='1%' y='470' rx='30' ry='30' width='35%' height='50' />
                <rect x='38%' y='470' rx='30' ry='30' width='35%' height='50' />
                <rect x='75%' y='470' rx='100%' ry='100%' width='50' height='50' />
                <rect x='0' y='540' rx='10' ry='10' width='80%' height='10' />
                <rect x='0' y='560' rx='10' ry='10' width='80%' height='10' />
                <rect x='0' y='580' rx='10' ry='10' width='80%' height='10' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
    </motion.div>
  );
}
