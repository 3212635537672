import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { Fragment, MutableRefObject } from 'react';
import { AppointmentsCardDateSidebar } from '../appointmentsCard';

type Props = {
  selectedDateKey: string;
  handleOnDaySelected: (date: string) => void;
  dateRefs: MutableRefObject<(HTMLDivElement | null)[]>;
  pastAppointmentsDates: [string, Array<{ key: string; date: string }>][];
};

export function DateSidebarItems({
  handleOnDaySelected,
  selectedDateKey,
  dateRefs,
  pastAppointmentsDates,
}: Props) {
  return (
    pastAppointmentsDates &&
    pastAppointmentsDates.map(([year, dates], index: number) => {
      return (
        <Fragment key={year}>
          {pastAppointmentsDates.length > 1 && (
            <p className={cn(TextLayout.body3, index > 0 && 'mt-3', 'text-product-gray-400')}>
              {year}
            </p>
          )}
          {dates.map((date: { key: string; date: string }, dateIndex: number) => (
            <div
              id={`date-${date.key}`}
              key={`date-${date.key + index + dateIndex}`}
              ref={(el) => (dateRefs.current[index + dateIndex] = el)}
              className='w-full max-w-[68px]'
            >
              <AppointmentsCardDateSidebar
                date={date.date}
                onSelect={() => handleOnDaySelected(date.key)}
                selected={selectedDateKey === date.key}
              />
            </div>
          ))}
        </Fragment>
      );
    })
  );
}
