import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth, LocalStorage } from '@/@types';
import { env } from '@/constants';
import { AuthHelper, JwtHelper, localStorageHelper } from '@/helper';
import { selfKey } from '@/hooks';
import { AuthZeroProvider, eventAnalytics, setBearerToken, setPatientIdHeader } from '@/providers';
import { AuthService } from '@/services';

export const ADMIN_EMAILS_LIST = [
  'cale.reid@atria.org',
  'teresa.rufin@atria.org',
  'celeste.leung@atria.org',
  'katie.chin@atria.org',
];

const AuthContext = createContext<Auth.Context.Type>(undefined!);

export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: ReactNode }) {
  const authStored = localStorageHelper.getItem(LocalStorage.Keys.AUTH);

  const navigate = useNavigate();
  const {
    isAuthenticated: isAuth0Authenticated,
    getAccessTokenSilently,
    loginWithRedirect: auth0loginWithRedirect,
    logout: auth0Logout,
  } = useAuth0();

  const [patient, setPatient] = useState<Auth.Context.Patient | undefined>(authStored?.patient);
  const [expiresIn, setExpiresIn] = useState<string | undefined>(authStored?.expiresIn);
  const [isSwitchAccountsVisible, setIsSwitchAccountsVisible] = useState(false);
  const [user, setUser] = useState<Auth.Context.User | undefined>(authStored?.user);

  const [hasMultiplePatients, setHasMultiplePatients] = useState<boolean>(
    authStored?.hasMultiplePatients || false
  );

  const isAdmin = useMemo(() => !!user?.isAdmin || false, [user?.isAdmin]);
  const isStaffAdmin = useMemo(
    () => !!isAdmin && ADMIN_EMAILS_LIST.includes(user!.email),
    [isAdmin, user]
  );
  const isAuthenticated = useMemo(
    () => !!user?.isAdmin || isAuth0Authenticated,
    [isAuth0Authenticated, user?.isAdmin]
  );
  const tokenInterval = useRef<NodeJS.Timeout>();

  const getAccessToken = useCallback(async (): Promise<Auth.Context.GetAccessToken> => {
    clearInterval(tokenInterval.current);

    const response = await getAccessTokenSilently({ detailedResponse: true });
    setBearerToken(response.access_token);

    const tokenRenewalMs = response.expires_in * 0.8 * 1000;
    tokenInterval.current = setInterval(() => {
      getAccessToken();
    }, tokenRenewalMs);

    const payload = JwtHelper.decode<Auth.JWT.Payload>(response.access_token);
    const patientsPayload = (payload?.patients || []).map(
      ({ patientId, relation, permissions }) => ({
        patientId,
        relation,
        permissions,
      })
    );

    const patients = patientsPayload.sort((a) => (a.relation === selfKey ? -1 : 1));

    const expiresISO = DateTime.now()
      .plus({ seconds: response.expires_in })
      .toJSDate()
      .toISOString();
    setExpiresIn(expiresISO);

    const permissions = patient
      ? patients.find((x) => x.patientId === patient.id)?.permissions
      : patients[0].permissions;

    const userPayload = {
      id: payload.sub,
      name: payload.userName,
      email: payload.userEmail,
      isAdmin: false,
      permissions: (permissions || []).map(AuthHelper.convertToUserPermissionEnum),
    };
    setUser(userPayload);

    const isMultiple = (payload.patients || [])?.length > 1;
    setHasMultiplePatients(isMultiple);

    localStorageHelper.updateItem({
      key: LocalStorage.Keys.AUTH,
      payload: {
        accessToken: response.access_token,
        expiresIn: expiresISO,
        hasMultiplePatients: isMultiple,
        user: userPayload,
      },
    });

    return {
      expiresIn: DateTime.now().plus({ seconds: response.expires_in }).toJSDate().toISOString(),
      id: payload.sub,
      name: payload.userName,
      email: payload.userEmail,
      patientId: payload.patientId,
      hasMultiplePatients: isMultiple,
      patients,
    };
  }, [getAccessTokenSilently, patient]);

  const loginWithRedirect = useCallback(
    (options: { email: string }) => {
      if (env.APP_FEATURE_FLAGS.IS_TO_USE_AUTH_ZERO_ACCESS_TOKEN) {
        auth0loginWithRedirect({ authorizationParams: { login_hint: options.email } });
        return;
      }
      const url = AuthZeroProvider.getLoginURL(options.email);
      window.location.href = url;
      return;
    },
    [auth0loginWithRedirect]
  );

  const signOutUser = useCallback(async () => {
    localStorageHelper.removeItem(LocalStorage.Keys.AUTH);
    localStorageHelper.removeItem(LocalStorage.Keys.CACHE);

    if (user?.isAdmin) {
      await AuthService.deleteAccessToken();
    } else {
      setPatientIdHeader(undefined);
      clearInterval(tokenInterval.current);
      await auth0Logout({
        logoutParams: {
          returnTo: `${env.APP_MEMBER_PORTAL_FRONTEND_URL}/sign-in`,
        },
      });
      eventAnalytics.reset();
    }

    setUser(undefined);
  }, [auth0Logout, user?.isAdmin]);

  const getCmoEmail = useCallback((p: Omit<Auth.Context.Patient, 'cmoEmail'>) => {
    let email = 'careteam@atria.org';
    if (p?.primaryProvider?.lastName) {
      email = `dr${p.primaryProvider.lastName.replace('-', '')}team@atria.org`.toLowerCase();
    }
    return email;
  }, []);

  const handleSetUser = useCallback((userData?: Auth.Context.User) => {
    if (userData) {
      const permissions = userData?.permissions;
      setUser((prev) => ({ ...userData, permissions: permissions || prev?.permissions }));
    } else {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    localStorageHelper.updateItem({
      key: LocalStorage.Keys.AUTH,
      payload: { user } as any,
    });
  }, [user]);

  const handleSetPatient = useCallback(
    (patientSelected: Omit<Auth.Context.Patient, 'cmoEmail'>) => {
      const result = { ...patientSelected, cmoEmail: getCmoEmail(patientSelected) };
      setPatient(result);

      const userData = localStorageHelper.getItem(LocalStorage.Keys.AUTH)?.user;

      if (userData) {
        handleSetUser({
          ...userData,
          permissions: patientSelected.permissions as Auth.Permissions.UserPermissions &
            Auth.Permissions.AdmPermissions,
        });
      }

      return result;
    },
    [getCmoEmail, handleSetUser]
  );

  const signInPatient = useCallback(
    (patientSelected: Omit<Auth.Context.Patient, 'cmoEmail'>) => {
      eventAnalytics.identify(patientSelected.id.toString());
      eventAnalytics.setAdmin(undefined);
      eventAnalytics.track('MemberSignIn', {
        signInStrategy: 'Auth0',
        patientId: patientSelected.id,
        patientFirstName: patientSelected.firstName,
        patientLastName: patientSelected.lastName,
        patientEmail: patientSelected.email,
      });
      setPatientIdHeader(patientSelected.id.toString());
      handleSetPatient(patientSelected);

      localStorageHelper.updateItem({
        key: LocalStorage.Keys.AUTH,
        payload: {
          patient: patientSelected,
        } as any,
      });
      navigate('/');
    },
    [handleSetPatient, navigate]
  );

  const signInAdmin = useCallback(
    async (patientSelected: Omit<Auth.Context.Patient, 'cmoEmail'>) => {
      const patientResult = handleSetPatient(patientSelected);
      localStorageHelper.setItem({
        key: LocalStorage.Keys.AUTH,
        payload: {
          accessToken: 'faketoken',
          expiresIn: 'fake-expires-in',
          hasMultiplePatients: false,
          user: {
            email: user!.email,
            id: user!.id,
            name: user!.name,
            isAdmin: true,
            permissions: user?.permissions,
          },
          patient: patientResult,
        },
      });
      localStorageHelper.setItem(
        { key: LocalStorage.Keys.CACHE, payload: { ignore: true } },
        { forever: false }
      );
      eventAnalytics.identify(user!.email);
      eventAnalytics.setAdmin(user);
      eventAnalytics.track('AdminSignIn', {
        adminEmail: patientSelected.email,
        patientId: patientSelected.id,
        patientFirstName: patientSelected.firstName,
        patientLastName: patientSelected.lastName,
      });
      navigate('/');
    },
    [handleSetPatient, navigate, user]
  );

  const handleSetHasPatients = useCallback((status: boolean) => {
    setHasMultiplePatients(status);
    localStorageHelper.updateItem({
      key: LocalStorage.Keys.AUTH,
      payload: {
        hasMultiplePatients: status,
      } as any,
    });
  }, []);

  const switchMember = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { patient: _patient, ...auth } = localStorageHelper.getItem(LocalStorage.Keys.AUTH)!;
    if (auth?.user?.isAdmin) {
      localStorageHelper.setItem({ key: LocalStorage.Keys.AUTH, payload: auth });
      setPatient(undefined);
      setPatientIdHeader(undefined);
      return navigate('/admin');
    }
    setIsSwitchAccountsVisible(true);
  }, [navigate]);

  const hasPermission = useCallback(
    (permission: Auth.Permissions.UserPermissions): boolean => {
      return user?.permissions?.includes(permission) || false;
    },
    [user?.permissions]
  );

  return (
    <AuthContext.Provider
      value={{
        patient,
        setPatient,
        user,
        setUser: handleSetUser,
        isAuthenticated,
        isAdmin,
        isStaffAdmin,
        setExpiresIn,
        expiresIn,
        signInPatient,
        hasMultiplePatients: hasMultiplePatients,
        setHasMultiplePatients: handleSetHasPatients,
        switchMember,
        loginWithRedirect,
        signOutUser,
        getAccessToken,
        signInAdmin,
        isSwitchAccountsVisible,
        setIsSwitchAccountsVisible,
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
