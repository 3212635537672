import { Appointments } from '@/@types';
import { useContactDialogContext } from '@/contexts';
import {
  AnalytesResultType,
  BooleanResultType,
  Button,
  CardNote,
  CircleIcon,
  Clock,
  cn,
  DateHelper,
  FormulaResultType,
  ImagingResultType,
  StringHelper,
  TextLayout,
} from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { Fragment, MutableRefObject, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppointmentsCardFooter } from '../appointmentsCard';
import { AtriaIcon } from '../icons';
import { ResultsList } from './resultsList';

type PastAppointmentsListProps = {
  appointments: Appointments.Response;
  sectionRefs: MutableRefObject<(HTMLDivElement | null)[]>;
  handleScheduleAppointment: VoidFunction;
};

const formatTime = (date: string) => {
  const dateObj = DateTime.fromISO(date);
  if (dateObj.toJSDate().getMinutes() > 0) {
    return dateObj.toFormat('h:mm');
  }
  return dateObj.toFormat('h');
};

export function PastAppointmentsList({
  appointments,
  sectionRefs,
  handleScheduleAppointment,
}: PastAppointmentsListProps) {
  const navigate = useNavigate();
  const { setContactDialogVisibility, setContactDialogTopic } = useContactDialogContext();
  const handleShowAskAQuestionDialog = useCallback(
    (startDate?: string, endDate?: string) => {
      if (!startDate && !endDate) {
        setContactDialogTopic(`Question about past appointments`);
        setContactDialogVisibility(true);
        return;
      }

      const start = formatTime(startDate as string);
      const end = formatTime(endDate as string);
      if (startDate) {
        const displayDate = `${DateHelper.formatDateToDisplay(DateTime.fromISO(startDate))} ${start}-${end}`;
        setContactDialogTopic(`Question about ${displayDate} appointment`);
      } else {
        setContactDialogTopic(`Question about past appointments`);
      }

      setContactDialogVisibility(true);
    },
    [setContactDialogVisibility, setContactDialogTopic]
  );

  const handleOnCardNoteClick = useCallback(
    (appointmentId: number, encounterId: number) => {
      navigate(`/appointments/${appointmentId}/encounter/${encounterId}/letter`);
    },
    [navigate]
  );

  const handleOnLabResultClick = useCallback(
    (labResultId: number, type: string) => {
      if (type === 'imaging') {
        navigate(`/results/imaging/${labResultId}`);
        return;
      }
      navigate(`/results/lab/${labResultId}`);
    },
    [navigate]
  );

  const appointmentList = useMemo(() => {
    return appointments.map((item) => {
      return {
        ...item,
        location:
          item.location === 'New York' || item.location === 'Palm Beach'
            ? `Atria ${item.location}`
            : item.location,
        appointments: item.appointments.map(({ id, title }) => ({ id, title })),
        labResults: item.labResults.map((result) => {
          const quantity = result.observations?.length;
          let params:
            | BooleanResultType['params']
            | AnalytesResultType['params']
            | ImagingResultType['params']
            | FormulaResultType['params'] = {
            name: StringHelper.firstLetterUppercase(result.description),
            description: result.patientNote,
            status: result.resultsStatus,
            quantity: quantity || 1,
            label: quantity && quantity > 1 ? 'results' : 'document',
          } as ImagingResultType['params'];

          let resultType = 'analytes';

          if (result.observations && result.observations[0]?.units) {
            const observation = result.observations[0];
            resultType = 'formula';
            params = {
              formula: `${observation.value} ${observation.units}`,
              status: StringHelper.firstLetterUppercase(observation.abnormalFlag as string),
              name: StringHelper.firstLetterUppercase(observation.analyteName),
              date: result.createdDateTime,
            } as unknown as FormulaResultType['params'];
          } else if (
            (result.observations &&
              result.observations.length === 1 &&
              result.observations[0]?.value === 'neg') ||
            (result.observations &&
              result.observations.length === 1 &&
              result.observations[0]?.value === 'pos')
          ) {
            const observation = result.observations[0];
            resultType = 'formula';
            params = {
              status: observation.value === 'neg' ? 'N' : 'P',
            } as BooleanResultType['params'];
          } else if (result.documentDescription) {
            resultType = 'imaging';
            params.name = StringHelper.firstLetterUppercase(result.documentDescription);
          }

          return {
            params,
            id: result.id,
            key: `${result.id}-${Math.random()}`,
            type: resultType,
          };
        }),
      };
    });
  }, [appointments]);

  return (
    <div className='flex flex-col'>
      {appointmentList.map((appointment, index) => (
        <Fragment key={appointment.key}>
          <div
            id={appointment.key}
            ref={(el) => (sectionRefs.current[index] = el)}
            className='flex flex-col w-full rounded-xl gap-4 bg-white p-3'
          >
            <div className='flex flex-col md:flex-row gap-3'>
              <div className='rounded-[10px] p-4 bg-product-sand-100 w-full'>
                <Clock
                  animated={false}
                  date={appointment.date}
                  startTime={appointment.startDate}
                  endTime={appointment.endDate}
                  variant='primary'
                />
              </div>
              <div className='rounded-[10px] p-4 bg-product-sand-100 w-full'>
                <div className='flex w-full flex-col gap-4'>
                  <h1 className='text-left text-2xl font-medium text-forest-100 mt-4 ml-4'>
                    {appointment.location || 'Schedule'}
                  </h1>
                  <ul className='p-4'>
                    {appointment.appointments.map((item) => (
                      <li
                        key={`appointment-schedule-${appointment.key}-${item.id}`}
                        className='flex w-full flex-row items-center justify-between py-2.5'
                      >
                        <div className='flex items-center'>
                          <CircleIcon />
                          <p className='text-forest-100'>{item.title}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {appointment?.appointmentNotes?.length > 0 && (
              <div className='flex flex-col rounded-[7px] p-8 bg-product-sand-100 gap-2'>
                <h2 className={cn(TextLayout.callout3, 'text-product-forest-100 mb-4')}>
                  Related appointment notes
                </h2>
                {appointment.appointmentNotes.map(({ title, encounterId, appointmentId }) => (
                  <div
                    className='flex justify-center'
                    key={`card-note-${appointment.key}-${encounterId}`}
                  >
                    <CardNote
                      key={encounterId}
                      buttonLabel='View notes'
                      onClick={() => handleOnCardNoteClick(appointmentId, encounterId)}
                      title={title}
                    />
                  </div>
                ))}
              </div>
            )}
            {appointment.labResults?.length > 0 && (
              <ResultsList
                results={appointment.labResults}
                handleOnLabResultClick={handleOnLabResultClick}
              />
            )}
            <div className='mb-1 [&>button]:w-full mx-5'>
              <Button
                variant='tertiary'
                label='Ask a question'
                size='large'
                onClick={() =>
                  handleShowAskAQuestionDialog(appointment.startDate, appointment.endDate)
                }
              />
            </div>
          </div>
          <AtriaIcon className='mx-0 w-full my-14 h-8' fill='#CBC6BD' />
        </Fragment>
      ))}
      <AppointmentsCardFooter
        text='Your care team is here to assist with your appointments. Please reach out at any time.'
        buttons={[
          { label: 'Schedule an appointment', onClick: handleScheduleAppointment },
          { label: 'Ask a question', onClick: () => handleShowAskAQuestionDialog() },
        ]}
      />
    </div>
  );
}
