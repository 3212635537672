import { Appointments } from '@/@types';
import {
  useApplicationContext,
  useAuthContext,
  useContactDialogContext,
  useToastContext,
} from '@/contexts';
import { useAppointments } from '@/hooks';
import { DateHelper, DateModule } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  AppointmentsCardDateSidebar,
  AppointmentsCardFooter,
  AppointmentsCardSchedule,
} from '../appointmentsCard';
import { AtriaIcon } from '../icons';
import { UpcomingAppointmentsLoading } from '../loading';
import { DialogModalFormAppointments } from '../modal';
import { AppointmentsBlank } from '../pastAppointments/appointmentsBlank';
import { UpcomingAppointmentsLocation } from './upcomingAppointmentsLocation';
type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

const Loading = ({ isLoading, children }: Props) => {
  return isLoading ? <UpcomingAppointmentsLoading /> : <>{children}</>;
};

export function UpcomingAppointments() {
  const { upcomingAppointmentsExtended, getUpcomingAppointmentsExtended } = useApplicationContext();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [constraints, setConstraints] = useState({ left: 0, right: 0 });
  const { confirmAppointments } = useAppointments();
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { patient } = useAuthContext();
  const { toast } = useToastContext();

  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContent,
    setContactDialogTitle,
    setContactDialogTopic,
  } = useContactDialogContext();

  useEffect(() => {
    const initalData = async () => {
      try {
        await getUpcomingAppointmentsExtended();
      } finally {
        setLoading(false);
      }
    };
    initalData();
  }, [getUpcomingAppointmentsExtended]);

  const handleScheduleAppointment = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTitle, setContactDialogVisibility, setContent]);

  const endDateTime = useCallback((endTime: string) => {
    const endDateObj = DateTime.fromISO(endTime);
    if (endDateObj.toJSDate().getMinutes() > 0) {
      return endDateObj.toFormat('h:mma').toLowerCase();
    }
    return endDateObj.toFormat('ha').toLowerCase();
  }, []);

  const startDateTime = useCallback((startTime: string) => {
    const startDateObj = DateTime.fromISO(startTime);
    if (startDateObj.toJSDate().getMinutes() > 0) {
      return startDateObj.toFormat('h:mm');
    }
    return startDateObj.toFormat('h');
  }, []);

  const handleAskQuestion = useCallback(
    (dateSelected?: string, startDate?: string, endDate?: string) => {
      if (!dateSelected || !startDate || !endDate) {
        setContactDialogTopic('Question about my appointment');
        setContactDialogVisibility(!isContactDialogVisible);
        return;
      }
      const date = DateTime.fromISO(dateSelected);
      const formatted = DateHelper.formatDateToDisplay(date).split(',')[0];
      setContactDialogTopic(
        `Question about [${formatted} ${startDateTime(startDate)}-${endDateTime(endDate)}] appointment`
      );
      setContactDialogVisibility(!isContactDialogVisible);
    },
    [
      endDateTime,
      isContactDialogVisible,
      setContactDialogTopic,
      setContactDialogVisibility,
      startDateTime,
    ]
  );

  const handleOnDaySelected = useCallback((key: string, index: number) => {
    document.getElementById(key)?.scrollIntoView({
      behavior: 'smooth',
    });
    setSelectedDateIndex(index);
  }, []);

  useEffect(() => {
    if (containerRef.current && sidebarRef.current) {
      const containerWidth = containerRef.current.scrollWidth;
      const viewportWidth = sidebarRef.current.clientWidth;

      setConstraints({ left: -(viewportWidth - containerWidth + 60), right: 0 });
    }
  }, [upcomingAppointmentsExtended]);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRefs.current.length === 0) return;

      const scrollPosition = window.scrollY;
      let newSelectedIndex = 0;

      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const offsetTop = section.offsetTop;
          const offsetBottom = offsetTop + section.offsetHeight;
          if (scrollPosition >= offsetTop - 50 && scrollPosition < offsetBottom - 50) {
            newSelectedIndex = index;
          }
        }
      });

      if (newSelectedIndex !== selectedDateIndex) {
        setSelectedDateIndex(newSelectedIndex);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [selectedDateIndex, sectionRefs]);

  const updateAppointment = useCallback(
    async (
      groupItem: Appointments.FindAllPatientsAppointments.GroupItem,
      { confirmed, canceled }: { confirmed: boolean; canceled: boolean }
    ) => {
      try {
        const date = DateTime.fromISO(groupItem.date);
        const formatted = DateHelper.formatDateToDisplay(date).split(',')[0];
        const startDate = startDateTime(groupItem.startDate);
        const endDate = endDateTime(groupItem.endDate);
        const dateTitle = `[${formatted} ${startDate}-${endDate}]`;
        const messageToCMO: string = canceled
          ? `Cancellation request for their ${dateTitle} appointment.`
          : confirmed
            ? `Appointment confirmation for their ${dateTitle} appointment.`
            : `Appointment unconfirmed for their ${dateTitle} appointment.`;
        const success = await confirmAppointments({
          canceled,
          confirmed,
          keyGroup: groupItem.key,
          patientId: patient!.id,
          message: messageToCMO,
        });
        if (success) {
          toast?.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${canceled ? 'Appointment canceled' : confirmed ? 'Appointment confirmed' : 'Appointment canceled'}`,
            life: 2500,
          });
        } else {
          toast?.current.show({
            severity: 'warn',
            summary: 'Error',
            detail: 'The operation could not be completed, please try again.',
            life: 2500,
          });
        }
      } catch (error: any) {
        const message =
          error?.response?.data?.messages?.[0]?.message ||
          error?.response?.data?.message ||
          'An error occurred, please try again later.';
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 2500,
        });
      }
    },
    [confirmAppointments, endDateTime, patient, startDateTime, toast]
  );

  if (!upcomingAppointmentsExtended || upcomingAppointmentsExtended.length === 0) {
    return (
      <AppointmentsBlank
        onClick={handleAskQuestion}
        text='You don’t have any upcoming appointments scheduled at this time.'
      />
    );
  }

  return (
    <Loading isLoading={loading}>
      <div
        ref={containerRef}
        className='w-full grid grid-cols-1 gap-5 md:gap-[79px] relative md:grid-cols-[1fr_93px]'
      >
        <div className='flex-1 w-full flex flex-col gap-0.5'>
          {upcomingAppointmentsExtended?.map((grouppedAppointments, index) => (
            <div key={grouppedAppointments.key} id={grouppedAppointments.key}>
              <div className='space-y-4 relative' ref={(el) => (sectionRefs.current[index] = el)}>
                <DateModule
                  animated={false}
                  date={grouppedAppointments.date}
                  endTime={grouppedAppointments.endDate}
                  onAskQuestion={() =>
                    handleAskQuestion(
                      grouppedAppointments.date,
                      grouppedAppointments.startDate,
                      grouppedAppointments.endDate
                    )
                  }
                  onUndo={() =>
                    updateAppointment(grouppedAppointments, { confirmed: false, canceled: false })
                  }
                  startTime={grouppedAppointments.startDate}
                  onConfirm={() =>
                    updateAppointment(grouppedAppointments, { confirmed: true, canceled: false })
                  }
                  canceled={grouppedAppointments.canceled}
                  confirmed={grouppedAppointments.confirmed}
                  threeDots={[
                    {
                      label: 'Cancel appointment',
                      onClick: () => {
                        updateAppointment(grouppedAppointments, {
                          confirmed: false,
                          canceled: true,
                        });
                      },
                    },
                    {
                      label: 'Reschedule appointment',
                      onClick: () => {
                        handleAskQuestion(
                          grouppedAppointments.date,
                          grouppedAppointments.startDate,
                          grouppedAppointments.endDate
                        );
                      },
                    },
                  ]}
                />
                <UpcomingAppointmentsLocation appointmentGroup={grouppedAppointments} />
                <AppointmentsCardSchedule
                  appointments={grouppedAppointments.appointments!}
                  doctor={grouppedAppointments.doctor}
                  lastModified={grouppedAppointments.lastModified}
                />
              </div>
              <AtriaIcon className='mx-0 w-full my-14 h-8 ' fill='#CBC6BD' />
            </div>
          ))}
          <AppointmentsCardFooter
            text='Your care team is here to assist with your appointments. Please reach out at any time.'
            buttons={[
              { label: 'Schedule an appointment', onClick: handleScheduleAppointment },
              { label: 'Ask a question', onClick: handleAskQuestion },
            ]}
          />
        </div>

        {isMobile ? (
          <div
            ref={sidebarRef}
            className='z-10 fixed bottom-0 flex flex-row items-center justify-start bg-product-sand-200 p-4 -ml-8'
          >
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              drag='x'
              className='flex w-full gap-6'
              dragConstraints={constraints}
            >
              {upcomingAppointmentsExtended?.map((appt, index) => (
                <AppointmentsCardDateSidebar
                  key={appt.key}
                  date={appt.date}
                  onSelect={() => handleOnDaySelected(appt.key, index)}
                  selected={selectedDateIndex === index}
                />
              ))}
            </motion.div>
          </div>
        ) : (
          <div className='-order-1 md:order-1 ' style={{ display: 'block', overflow: 'visible' }}>
            <div className='flex-row md:flex flex md:flex-col items-center justify-start gap-[7px] border-t border-b md:border-none sticky top-[48px] overflow-hidden max-h-[95vh]'>
              {upcomingAppointmentsExtended?.map((appt, index) => {
                return (
                  <AppointmentsCardDateSidebar
                    key={appt.key}
                    date={appt.date}
                    onSelect={() => handleOnDaySelected(appt.key, index)}
                    selected={selectedDateIndex === index}
                  />
                );
              })}
              <div className='w-full flex justify-center max-w-[68px] mb-3'>
                <AtriaIcon className='mx-0 h-[50px] w-[25px]' fill='#CBC6BD' />
              </div>
            </div>
          </div>
        )}
      </div>
    </Loading>
  );
}
