import {
  AtriaIcon,
  DialogModalFormAppointments,
  PastAppointmentsList,
  PastAppointmentsLoading,
} from '@/components';
import { useApplicationContext, useContactDialogContext } from '@/contexts';
import { cn } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AppointmentsBlank, DateSidebarItems, DateSidebarMobile } from './';

export function PastAppointments() {
  const { pastAppointments, getPastAppointments } = useApplicationContext();
  const [loading, setLoading] = useState(true);
  const { isContactDialogVisible, setContactDialogVisibility, setContent, setContactDialogTitle } =
    useContactDialogContext();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [constraints, setConstraints] = useState({ left: 0, right: 0 });
  const [selectedDateKey, setSelectedDateKey] = useState<string>('');
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const dateMenuRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const dateRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [menuItemClicked, setMenuItemClicked] = useState(false);

  const handleOnClickOpenDialog = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTitle, setContactDialogVisibility, setContent]);

  const handleOnDaySelected = useCallback((key: string) => {
    setMenuItemClicked(true);
    document.getElementById(key)?.scrollIntoView({
      behavior: 'smooth',
    });
    setSelectedDateKey(key);
  }, []);

  useEffect(() => {
    if (containerRef.current && sidebarRef.current) {
      const containerWidth = containerRef.current.scrollWidth;
      const viewportWidth = sidebarRef.current.clientWidth;

      setConstraints({ left: -(viewportWidth - containerWidth + 60), right: 0 });
    }
  }, []);

  const handleScroll = useCallback(
    (root: HTMLElement) => () => {
      if (sectionRefs.current.length === 0 || menuItemClicked) return;
      const scrollPosition = root.scrollTop;
      let newSelectedKey = '';
      sectionRefs.current.forEach((section) => {
        if (section) {
          const offsetTop = section.offsetTop;
          const offsetBottom = offsetTop + section.offsetHeight;
          if (scrollPosition >= offsetTop - 50 && scrollPosition < offsetBottom - 50) {
            newSelectedKey = section.id || '';
          }
        }
      });

      if (!!newSelectedKey && newSelectedKey !== selectedDateKey) {
        setSelectedDateKey(newSelectedKey);
        const newDateElement = dateRefs.current.find(
          (element) => element?.id === `date-${newSelectedKey}`
        );
        if (newDateElement) {
          dateMenuRef.current?.scrollTo({ top: newDateElement.offsetTop, behavior: 'smooth' });
          sidebarRef.current?.scrollTo({ left: newDateElement.offsetLeft, behavior: 'smooth' });
        }
      }
    },
    [menuItemClicked, selectedDateKey]
  );

  const handleScrollEnd = useCallback(() => setMenuItemClicked(false), []);

  useEffect(() => {
    const root = document.getElementById('root');
    if (!root) {
      return;
    }
    const onScroll = handleScroll(root);

    root.addEventListener('scroll', onScroll);
    root.addEventListener('scrollend', handleScrollEnd);
    return () => {
      root.removeEventListener('scroll', onScroll);
      root.addEventListener('scrollend', () => setMenuItemClicked(false));
    };
  }, [selectedDateKey, sectionRefs, menuItemClicked, handleScroll, handleScrollEnd]);

  useEffect(() => {
    const initLoad = async () => {
      try {
        await getPastAppointments();
      } finally {
        setLoading(false);
      }
    };
    initLoad();
  }, [getPastAppointments]);

  useEffect(() => {
    if (pastAppointments && pastAppointments[0]) {
      setSelectedDateKey(pastAppointments[0].key);
    }
  }, [pastAppointments]);

  const pastAppointmentsDates = useMemo(() => {
    const yearMap = new Map();
    pastAppointments?.forEach(({ date, key }: { date: string; key: string }) => {
      const year = DateTime.fromFormat(date, 'yyyy-MM-dd').year;
      const yearItem = yearMap.get(year) || [];
      const dateObj = {
        key,
        date,
      };

      yearItem.push(dateObj);
      yearMap.set(year, yearItem);
    });
    return Array.from(yearMap);
  }, [pastAppointments]);

  if ((!pastAppointments || pastAppointments.length === 0) && !loading) {
    return (
      <AppointmentsBlank
        onClick={handleOnClickOpenDialog}
        text='You don’t have any past appointments yet.'
      />
    );
  }

  return (
    <Loading isLoading={loading}>
      <div
        ref={containerRef}
        className={cn(
          'w-full grid grid-cols-1 gap-5 md:gap-[79px] relative',
          'md:grid-cols-[1fr_93px]'
        )}
      >
        <PastAppointmentsList
          appointments={pastAppointments!}
          handleScheduleAppointment={handleOnClickOpenDialog}
          sectionRefs={sectionRefs}
        />
        {isMobile ? (
          <DateSidebarMobile
            sidebarRef={sidebarRef}
            selectedDateKey={selectedDateKey}
            constraints={constraints}
            handleOnDaySelected={handleOnDaySelected}
            dateRefs={dateRefs}
            pastAppointmentsDates={pastAppointmentsDates}
          />
        ) : (
          <div
            className={cn(
              '-order-1 md:order-1 pr-1',
              pastAppointmentsDates?.length > 1 ? 'block' : 'flex'
            )}
            style={{ overflow: 'clip' }}
          >
            <div
              ref={dateMenuRef}
              className='flex-row md:flex flex md:flex-col items-center justify-start gap-[7px] border-t border-b md:border-none sticky top-[48px] overflow-hidden max-h-[95vh]'
            >
              <DateSidebarItems
                handleOnDaySelected={handleOnDaySelected}
                selectedDateKey={selectedDateKey}
                dateRefs={dateRefs}
                pastAppointmentsDates={pastAppointmentsDates}
              />
              <div className='w-full flex justify-center max-w-[68px] mb-3'>
                <AtriaIcon className='mx-0 h-[50px] w-[25px]' fill='#CBC6BD' />
              </div>
            </div>
          </div>
        )}
      </div>
    </Loading>
  );
}

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

const Loading = ({ isLoading, children }: Props) => {
  return isLoading ? <PastAppointmentsLoading /> : <>{children}</>;
};
