import { Variants, motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { BasicCard } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function PastAppointmentsLoading() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <motion.div
      initial='hidden'
      animate='show'
      exit='hidden'
      variants={container}
      className='grid grid-cols-1 justify-start gap-6'
    >
      <motion.div variants={itemVariant} className='space-y-8'>
        <BasicCard className='shadow-none rounded-xl px-1'>
          <ContentLoader
            speed={2}
            width='100%'
            height={600}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0' y='0' rx='10' ry='10' width='100%' height='450' />
                <rect x='0' y='470' rx='10' ry='10' width='100%' height='100' />
              </>
            ) : (
              <>
                <rect x='0' y='0' rx='10' ry='10' width='40%' height='450' />
                <rect x='41%' y='0' rx='10' ry='10' width='40%' height='450' />
                <rect x='85%' y='0' rx='4' ry='4' width='50' height='50' />
                <rect x='85%' y='60' rx='4' ry='4' width='50' height='50' />
                <rect x='85%' y='120' rx='4' ry='4' width='50' height='50' />
                <rect x='0' y='470' rx='10' ry='10' width='81%' height='120' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
    </motion.div>
  );
}
