import { motion } from 'framer-motion';
import { MutableRefObject } from 'react';
import { DateSidebarItems } from './';

type Props = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>;
  selectedDateKey: string;
  handleOnDaySelected: (date: string) => void;
  dateRefs: MutableRefObject<(HTMLDivElement | null)[]>;
  constraints: {
    left: number;
    right: number;
  };
  pastAppointmentsDates: [string, Array<{ key: string; date: string }>][];
};

export function DateSidebarMobile({
  sidebarRef,
  handleOnDaySelected,
  selectedDateKey,
  dateRefs,
  constraints,
  pastAppointmentsDates,
}: Props) {
  return (
    <div
      ref={sidebarRef}
      className='z-10 fixed bottom-0 flex flex-row items-center justify-start bg-product-sand-200 p-4 -ml-8 w-full overflow-auto'
    >
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        drag='x'
        className='flex w-full gap-6 items-center'
        dragConstraints={constraints}
      >
        <DateSidebarItems
          handleOnDaySelected={handleOnDaySelected}
          selectedDateKey={selectedDateKey}
          dateRefs={dateRefs}
          pastAppointmentsDates={pastAppointmentsDates}
        />
      </motion.div>
    </div>
  );
}
