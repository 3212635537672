import { useCallback } from 'react';

import { Appointment, Appointments } from '@/@types';
import { patientsService } from '@/services';

export function usePatientAppointments() {
  const findPatientAppointments = useCallback(
    async (
      id: number,
      params: { startDate: string; endDate: string; atriaAppointment?: boolean }
    ): Promise<Appointment[]> => {
      const { data } = await patientsService.getPatientAppointments(id, params);
      return data;
    },
    []
  );

  const findPatientUpcomingAppointments = useCallback(
    async (params: Appointments.FindAllPatientsAppointments.Params) => {
      try {
        const { data } = await patientsService.findAllPatientsAppointments(params);
        return data;
      } catch (error) {
        return [];
      }
    },
    []
  );

  const findPatientPastAppointments = useCallback(
    async (
      id: number,
      params: {
        startDate: string;
        endDate: string;
        atriaAppointment?: boolean;
        includesDocuments?: boolean;
        athenaAppointments?: boolean;
      }
    ) => {
      const { data } = await patientsService.getPatientPastAppointments(id, params);
      return data;
    },
    []
  );

  return {
    findPatientAppointments,
    findPatientUpcomingAppointments,
    findPatientPastAppointments,
  };
}
