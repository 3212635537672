import { DateTime } from 'luxon';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ApplicationContextType,
  AppointmentSelectionType,
  Doctor,
  FindDashboardSummary,
  LocalStorage,
  MedicalRecords,
} from '@/@types';
import { localStorageHelper } from '@/helper';
import {
  useCareTeam,
  useDashboard,
  useMedicalRecords,
  useMedications,
  usePatientAppointments,
  usePatientDocuments,
  usePatientImagingResults,
  usePatientLabResults,
  useResources,
} from '@/hooks';
import { useImmunizations } from '@/hooks/useImmunizations';
import { useAuthContext, useLoaderContext } from '.';

const ApplicationContext = createContext<ApplicationContextType>(undefined!);

export const useApplicationContext = () => useContext(ApplicationContext);

const today = DateTime.now().toFormat('yyyy-MM-dd');
const upcomingAppointmentsEndDate = DateTime.now().plus({ year: 1 }).toFormat('yyyy-MM-dd');
const previousAppointmentsStartDate = DateTime.now().minus({ year: 5 }).toFormat('yyyy-MM-dd');

export function ApplicationProvider({ children }: { children: ReactNode }) {
  const { startLoader, stopLoader } = useLoaderContext();
  const { patient, setPatient } = useAuthContext();
  const { findPatientDocuments } = usePatientDocuments();
  const { findPatientAppointments, findPatientUpcomingAppointments, findPatientPastAppointments } =
    usePatientAppointments();
  const { findPatientImagingResults } = usePatientImagingResults();
  const { findPatientLabResults } = usePatientLabResults();
  const { findPatientsMedicationsList } = useMedications();
  const { findResources } = useResources();
  const { findSummary } = useDashboard();
  const { findDoctor, findAllDoctors, findSpecialistsByPatient } = useCareTeam();
  const { findPatientImmunizations } = useImmunizations();
  const { findAllPatientMedicalRecords } = useMedicalRecords();

  const [documents, setDocuments] = useState<ApplicationContextType['documents']>();
  const [imagingResults, setImagingResults] = useState<ApplicationContextType['imagingResults']>();
  const [labResults, setLabResults] = useState<ApplicationContextType['labResults']>();
  const [medications, setMedications] = useState<ApplicationContextType['medications']>();
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    ApplicationContextType['upcomingAppointments']
  >([]);
  const [upcomingAppointmentsExtended, setUpcomingAppointmentsExtended] = useState<
    ApplicationContextType['upcomingAppointmentsExtended']
  >([]);

  const [previousAppointments, setPreviousAppointments] =
    useState<ApplicationContextType['previousAppointments']>();
  const [pastAppointments, setPastAppointments] =
    useState<ApplicationContextType['pastAppointments']>();

  const [resources, setResources] = useState<ApplicationContextType['resources']>();
  const [dashboardSummary, setDashboardSummary] = useState<FindDashboardSummary>();
  const [doctor, setDoctor] = useState<Doctor>();
  const [allDoctors, setAllDoctors] = useState<Doctor[]>();
  const [locations, setLocations] = useState<ApplicationContextType['locations']>();
  const [immunizations, setImmunizations] = useState<ApplicationContextType['immunizations']>();
  const [patientMedicalRecords, setPatientMedicalRecords] = useState<
    Array<MedicalRecords.Type> | undefined
  >();
  const [providers, setProviders] = useState<ApplicationContextType['providers']>();

  const [selectedAppointmentsPage, setSelectedAppointmentsPage] = useState<string>(
    AppointmentSelectionType.UPCOMING.toString()
  );

  const allDoctorsGroupedByTags = useMemo(() => {
    const groupedDoctorsList = new Map<string, Doctor[]>();

    allDoctors?.forEach((item) => {
      item.tags?.forEach((tag) => {
        const formattedTag = `${tag.charAt(0).toUpperCase()}${tag.substring(1)}`;

        if (!groupedDoctorsList || !groupedDoctorsList.has(formattedTag)) {
          groupedDoctorsList.set(formattedTag, []);
        }

        groupedDoctorsList.set(formattedTag, [...groupedDoctorsList.get(formattedTag)!, item]);
      });
    });

    return groupedDoctorsList;
  }, [allDoctors]);

  const getDocuments = useCallback(async () => {
    if (patient) {
      const documentsResponse = await findPatientDocuments(patient.id);
      setDocuments(documentsResponse);
    }
  }, [findPatientDocuments, patient]);

  const getImagingResults = useCallback(async () => {
    if (patient) {
      const imagingResultsResponse = await findPatientImagingResults(patient.id);
      setImagingResults(imagingResultsResponse);
    }
  }, [findPatientImagingResults, patient]);

  const getLabResults = useCallback(async () => {
    if (patient) {
      const labResultsResponse = await findPatientLabResults(patient.id);
      setLabResults(labResultsResponse);
    }
  }, [findPatientLabResults, patient]);

  const getMedications = useCallback(async () => {
    if (patient) {
      const medicationsResponse = await findPatientsMedicationsList(patient.id);

      setMedications(medicationsResponse);
    }
  }, [findPatientsMedicationsList, patient]);

  const getUpcomingAppointments = useCallback(async () => {
    if (!patient || upcomingAppointments) return;
    startLoader();
    const upcomingAppointmentsResponse = await findPatientAppointments(patient.id, {
      startDate: today,
      endDate: upcomingAppointmentsEndDate,
      atriaAppointment: false,
    });
    setUpcomingAppointments(
      upcomingAppointmentsResponse.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.end).getTime()
      )
    );
    stopLoader();
  }, [findPatientAppointments, upcomingAppointments, patient, startLoader, stopLoader]);

  const getUpcomingAppointmentsExtended = useCallback(async () => {
    if (!patient || upcomingAppointmentsExtended.length > 0) return;
    const upcomingAppointmentsResponse = await findPatientUpcomingAppointments({
      patientId: patient.id,
      startDate: today,
      endDate: upcomingAppointmentsEndDate,
    });

    const storedAppointments = localStorageHelper.getItem(LocalStorage.Keys.AUTH)?.patient
      ?.upcomingAppointments;

    const updatedAppointments = upcomingAppointmentsResponse.map((appointment) => {
      return {
        ...appointment,
        confirmed:
          appointment.confirmed ||
          storedAppointments?.find((storedAppointment) => storedAppointment.key === appointment.key)
            ?.confirmed ||
          false,
        canceled:
          storedAppointments?.find((storedAppointment) => storedAppointment.key === appointment.key)
            ?.canceled || false,
      };
    });

    setUpcomingAppointmentsExtended(updatedAppointments);
  }, [patient, upcomingAppointmentsExtended.length, findPatientUpcomingAppointments]);

  useEffect(() => {
    const updatedAppointments = upcomingAppointmentsExtended.map((appt) => ({
      key: appt.key,
      confirmed: appt.confirmed,
      canceled: appt.canceled,
    }));
    const user = localStorageHelper.getItem(LocalStorage.Keys.AUTH);
    if (user) {
      localStorageHelper.setItem({
        key: LocalStorage.Keys.AUTH,
        payload: {
          ...user,
          patient: {
            ...user.patient!,
            upcomingAppointments: updatedAppointments!,
          },
        },
      });
    }
  }, [upcomingAppointmentsExtended]);

  const getPreviousAppointments = useCallback(async () => {
    if (!patient || previousAppointments) return;
    startLoader();
    const previousAppointmentsResponse = await findPatientAppointments(patient.id, {
      startDate: previousAppointmentsStartDate,
      endDate: today,
      atriaAppointment: false,
    });
    setPreviousAppointments(
      previousAppointmentsResponse.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )
    );
    stopLoader();
  }, [findPatientAppointments, patient, previousAppointments, startLoader, stopLoader]);

  const getPastAppointments = useCallback(async () => {
    if (!patient || pastAppointments) return;
    const appointments = await findPatientPastAppointments(patient.id, {
      startDate: previousAppointmentsStartDate,
      endDate: today,
      atriaAppointment: true,
      athenaAppointments: true,
      includesDocuments: true,
    });
    setPastAppointments(appointments);
  }, [patient, pastAppointments, findPatientPastAppointments]);

  const getResources = useCallback(async () => {
    const resourcesResponse = await findResources();

    setResources(resourcesResponse);
  }, [findResources]);

  const findDashboardSummary = useCallback(async () => {
    if (!patient) return;
    const data = await findSummary(patient!.id);
    setDashboardSummary(data);
  }, [findSummary, patient]);

  const getDoctor = useCallback(
    async (id: string) => {
      if (!patient) return;

      const doctorResponse = await findDoctor(id);
      if (!doctorResponse) return;
      setDoctor(doctorResponse);
    },
    [findDoctor, patient]
  );

  const getAllDoctors = useCallback(async () => {
    if (!patient) return;

    const doctors = await findAllDoctors();
    if (!doctors) return;
    setAllDoctors(doctors.doctors);

    setLocations(doctors.locations);
  }, [findAllDoctors, patient]);

  const getImmunizations = useCallback(async () => {
    if (!patient) return;
    const response = await findPatientImmunizations(patient.id);
    setImmunizations(response);
  }, [findPatientImmunizations, patient]);

  const findPatientMedicalRecords = useCallback(async () => {
    const data = await findAllPatientMedicalRecords(patient!.id);
    setPatientMedicalRecords(data);
  }, [findAllPatientMedicalRecords, patient]);

  const getSpecialists = useCallback(async () => {
    const data = await findSpecialistsByPatient();
    if (!data) return;
    setProviders(data.members);
  }, [findSpecialistsByPatient]);

  const reset = useCallback(() => {
    setPatient(undefined);
    setDocuments(undefined);
    setImagingResults(undefined);
    setLabResults(undefined);
    setMedications(undefined);
    setUpcomingAppointments(undefined);
    setPreviousAppointments(undefined);
    setPastAppointments(undefined);
    setResources(undefined);
    setDashboardSummary(undefined);
    setDoctor(undefined);
    setImmunizations(undefined);
    setPatientMedicalRecords(undefined);
    setAllDoctors(undefined);
    setLocations(undefined);
    setProviders(undefined);
    setUpcomingAppointmentsExtended([]);
    setSelectedAppointmentsPage(AppointmentSelectionType.UPCOMING.toString());
  }, [setPatient]);

  return (
    <ApplicationContext.Provider
      value={{
        documents,
        imagingResults,
        labResults,
        medications,
        upcomingAppointments,
        previousAppointments,
        resources,
        dashboardSummary,
        doctor,
        allDoctors,
        locations,
        immunizations,
        providers,
        setUpcomingAppointmentsExtended,
        upcomingAppointmentsExtended,
        patientMedicalRecords,
        allDoctorsGroupedByTags,
        pastAppointments,
        selectedAppointmentsPage,
        getDocuments,
        getImagingResults,
        getLabResults,
        getMedications,
        getPreviousAppointments,
        getUpcomingAppointments,
        getResources,
        findDashboardSummary,
        reset,
        getDoctor,
        getAllDoctors,
        getImmunizations,
        findPatientMedicalRecords,
        getSpecialists,
        getUpcomingAppointmentsExtended,
        getPastAppointments,
        setSelectedAppointmentsPage,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}
