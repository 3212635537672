import { useCallback, useEffect } from 'react';

import { CustomTableHeader, Medications } from '@/@types';
import {
  BasicCard,
  Disclaimer,
  MedicationsTable,
  MedicationsTableAdmin,
  PageTitle,
} from '@/components';

import {
  useApplicationContext,
  useAuthContext,
  useContactDialogContext,
  usePageContext,
} from '@/contexts';

const tableHeaders: CustomTableHeader[] = [
  { key: 'name', label: 'Name' },
  { key: 'instructions', label: 'Instructions', width: 400 },
];

export function MedicationsPage() {
  const { medications, getMedications } = useApplicationContext();
  const { isAdmin } = useAuthContext();
  const { setPageTitle, pageTitle, setActions } = usePageContext();
  const {
    setContactDialogVisibility,
    setContactDialogTopic,
    setContactDialogTitle,
    setContactDialogMessagePlaceholder,
    setIsContactDialogBodyRequired,
    setUseTopicSelect,
    setOptionsSelect,
    setTopicLabel,
  } = useContactDialogContext();

  const handleAskQuestion = useCallback(
    (item: Medications.Medication) => {
      setContactDialogTopic(`Question about ${item.medication}`);
      setContactDialogVisibility(true);
    },
    [setContactDialogTopic, setContactDialogVisibility]
  );

  const handleRequestRefill = useCallback(
    (
      items: Medications.Medication['medication'][],
      medication: Medications.Medication['medication']
    ) => {
      setContactDialogTitle('Request a refill');
      setUseTopicSelect(items.length > 0 || false);
      setTopicLabel('Medication');
      setOptionsSelect(items);

      setContactDialogTopic(items.length > 0 ? [medication] : 'Request a refill');
      setContactDialogMessagePlaceholder('Any other details? (optional)');
      setIsContactDialogBodyRequired(false);
      setContactDialogVisibility(true);
    },
    [
      setContactDialogMessagePlaceholder,
      setContactDialogTitle,
      setContactDialogTopic,
      setContactDialogVisibility,
      setIsContactDialogBodyRequired,
      setOptionsSelect,
      setTopicLabel,
      setUseTopicSelect,
    ]
  );

  useEffect(() => {
    setPageTitle('Medications');
    setActions({
      buttons: [
        {
          label: 'Request a refill',
          onClick: () =>
            handleRequestRefill(medications?.list.map((item) => item.medication) || [], ''),
          variant: 'quaternary',
          icon: 'add',
        },
      ],
    });
  }, [handleRequestRefill, medications?.list, setActions, setPageTitle]);

  useEffect(() => {
    if (!medications) {
      getMedications();
    }
  }, [getMedications, medications]);

  const handleOpenContactCareButton = useCallback(() => {
    setContactDialogTopic(pageTitle);
    setIsContactDialogBodyRequired(true);
    setContactDialogVisibility(true);
  }, [
    pageTitle,
    setContactDialogTopic,
    setContactDialogVisibility,
    setIsContactDialogBodyRequired,
  ]);

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Your medications' />
      <div>
        <BasicCard className='px-0'>
          <div className='mb-4'>
            <Disclaimer
              text={
                <p>
                  If you have questions about your medications or if there have been any changes
                  since your last review, please{' '}
                  <span className='underline cursor-pointer' onClick={handleOpenContactCareButton}>
                    contact your care team.{''}
                  </span>{' '}
                  We will promptly review and update it as needed.
                </p>
              }
            />
          </div>
          {medications && medications.list.length > 0 && (
            <MedicationsTable
              medications={medications.list}
              tableHeaders={tableHeaders}
              handleAskQuestion={handleAskQuestion}
              handleRequestRefill={handleRequestRefill}
              isActionEnabled={true}
            />
          )}
        </BasicCard>
        {/* TODO: Add permission to check if the admin has permission to view this page */}
        {isAdmin && medications && (
          <MedicationsTableAdmin
            medications={medications}
            tableHeaders={tableHeaders}
            isActionEnabled={false}
          />
        )}
      </div>
    </div>
  );
}
